<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:col-9 md:offset-3">
        <div class="row-12 gutter-12">
          <div v-for="galerie in payload.galerien" :key="galerie.id" class="md:col-4">
            <Go :to="'/' + locale + galerie.linkbildergalerie">
              <div class="content bg-lightgray">
                <img class="teaser-img block" :src="galerie.teaserbild.large" alt="">
                <div class="p-8 flex">
                  <h3 class="inline-block" v-html="galerie.titel" />
                  <Arrow class="ml-auto mt-1.5" />
                </div>

              </div>
            </Go>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Arrow from '../../assets/images/Pfeil.svg';

export default {
  components: {
    Arrow,
  },
  props: {
    payload: Object,
  },
  computed: {
    locale() {
      return this.$store.state.router.locale;
    },
  },
  mounted() {
    // console.log(this.payload);
    // console.log(this.locale);
  },
};
</script>

<style lang="scss" scoped>
.teaser-img {
  object-fit: cover;
  width: 100%;
  height: px(300);
}

.content {
  height: 100%;
  transition: box-shadow 0.25s ease-out;
  &:hover {
    box-shadow: 0px 2px 10px rgba(black, 0.3);
  }
  svg {
    height: px(30);
    min-width: px(30);
    width: px(30);
  }
}
</style>
